import React, { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../components/Layout/styled"

import SeoComp from "../../components/SeoComp"
import { Select } from "../../components/Products/styled"
import {
  LyneNewSizeSelectorLyneUpFemale,
  LyneNewSizeSelectorLyneUpMale,
} from "../../components/LyneNewSizeSelector"
import {
  SizeSelectorLyneUpFemale,
  SizeSelectorLyneUpMale,
} from "../../components/SizeSelector"
import SizeModelSection from "../../components/Layout/SizeModelSection"
import { ProductSizeVariationBoxWrapper } from "../../components/Products/ProductSizeVariationBox"

const SizesPage = ({ intl, data }) => {
  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [recommendedSize, setRecommendedSize] = useState("")
  const [chooseModel, setChooseModel] = useState("new-model")
  const [chooseGender, setChooseGender] = useState("")

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      if (data.recommendation.size?.length) {
        setRecommendedSize(data.recommendation.size)

        seterrorSize("")
      } else {
        seterrorSize(
          intl.formatMessage({ id: "size_selector.size_error_message" })
        )
      }
    }
  }

  useEffect(() => {
    setRecommendedSize("")
    seterrorSize("")
  }, [chooseModel, chooseGender])

  return (
    data &&
    data.allDirectusSeoTranslation.nodes.length > 0 &&
    data.allDirectusCommonTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
        />
        <SectionContainer>
          <SectionInnerWrapper align="flex-start">
            <Container>
              <SizeBoxWrapper>
                {data.allDirectusCommonTranslation.nodes[0]
                  .size_model_section && (
                  <SizeModelSection
                    data={
                      data.allDirectusCommonTranslation.nodes[0]
                        .size_model_section
                    }
                    handleChange={setChooseModel}
                    chooseValue={chooseModel}
                    section="model"
                  />
                )}
              </SizeBoxWrapper>
              <SizeBoxWrapper>
                {data.allDirectusCommonTranslation.nodes[0]
                  .size_gender_section && (
                  <SizeModelSection
                    data={
                      data.allDirectusCommonTranslation.nodes[0]
                        .size_gender_section
                    }
                    handleChange={setChooseGender}
                    chooseValue={chooseGender}
                  />
                )}
              </SizeBoxWrapper>
              <div
                style={{
                  marginBottom: 5,
                  fontWeight: "bold",
                  fontFamily: "Gotham",
                }}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "lyne_size_calculate_text",
                  }),
                }}
              ></div>

              <ProductSizeVariationBoxWrapper
                show={
                  !chooseGender || chooseGender == "" || chooseGender === "male"
                }
                style={{ margn: "10px 0px" }}
              >
                {chooseModel === "new-model" ? (
                  <LyneNewSizeSelectorLyneUpMale
                    setLoading={setLoading}
                    onChange={handleSizeRecommendationUpdate}
                  />
                ) : (
                  <SizeSelectorLyneUpMale
                    setLoading={setLoading}
                    onChange={handleSizeRecommendationUpdate}
                  />
                )}
              </ProductSizeVariationBoxWrapper>

              <ProductSizeVariationBoxWrapper
                show={chooseGender === "female"}
                style={{ margn: "10px 0px" }}
              >
                {chooseModel === "new-model" ? (
                  <LyneNewSizeSelectorLyneUpFemale
                    onChange={handleSizeRecommendationUpdate}
                  />
                ) : (
                  <SizeSelectorLyneUpFemale
                    onChange={handleSizeRecommendationUpdate}
                  />
                )}
              </ProductSizeVariationBoxWrapper>
              <div
                style={{
                  fontWeight: "bold",
                  fontFamily: "Gotham",
                }}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "lyneup_size_calculation_text",
                  }),
                }}
              ></div>
              <div
                style={{
                  marginBottom: 5,
                  fontWeight: "bold",
                  fontFamily: "GothamLight",
                }}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "lyne_no_size_calculate_text",
                  }),
                }}
              ></div>
              {errorSize && !loading && (
                <div
                  className="warning"
                  style={{
                    marginTop: 5,
                    fontWeight: "bold",
                    fontFamily: "Gotham",
                  }}
                  dangerouslySetInnerHTML={{ __html: errorSize }}
                ></div>
              )}

              <SizeSelectorBoxSection
                active={recommendedSize}
                style={{
                  display: errorSize ? "none" : "flex",
                }}
              >
                <SizeSelectorBoxWrapper>
                  <div className="size_drop_down">
                    <div className="size_drop_down_child">
                      <Select
                        id="pa_taille_classique"
                        name="Size"
                        value={recommendedSize}
                        pageName="lyneup"
                        onChange={e => {}}
                      >
                        <option value={""}>
                          {intl.formatMessage({
                            id: "lyneup_percko_size_text",
                          })}
                        </option>
                        {chooseModel === "new-model" &&
                          data.allDirectusLynePageTranslation.nodes[0].lyneup_size.item.map(
                            value => (
                              <option value={value} key={`Size-${value}`}>
                                {intl.formatMessage({
                                  id: "lyne_percko_size_selected",
                                })}
                                {" : "}
                                {value}
                              </option>
                            )
                          )}
                        {chooseModel === "old-model" &&
                          data.allDirectusLyneupTranslation.nodes[0].size.item.map(
                            value => (
                              <option value={value} key={`Size-${value}`}>
                                {intl.formatMessage({
                                  id: "lyne_percko_size_selected",
                                })}
                                {" : "}
                                {value}
                              </option>
                            )
                          )}
                      </Select>
                    </div>
                  </div>
                </SizeSelectorBoxWrapper>
              </SizeSelectorBoxSection>
            </Container>
          </SectionInnerWrapper>
        </SectionContainer>
      </Fragment>
    )
  )
}

const Container = styled.div`
  color: #262626;
  font-size: 14px;
  line-height: 1.6;
  font-family: "GothamLight";
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  flex-direction: column;

  .ftl-widget {
    text-align: center;
    border: 1px solid;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    border-color: #000;
    color: #000000;
  }
  .ftl-widget:hover {
    background-color: #f37f50;
    color: #ffffff;
    opacity: 1;
  }
  .ftl-widget:hover .ftl-widget-logo {
    background-image: url("https://static.percko.com/uploads/20533fa3-2621-420a-b697-c7ab89d55b93.png");
  }
  .main-action-button {
    display: none;
  }

  #fitle-placeholder {
    width: 50%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  #size-women {
    width: 50%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .medium-6 {
    max-width: 25%;
    margin-right: auto;
  }
  .medium-3 {
    margin-left: auto;
    min-width: 150px;
    margin-right: 6px;
  }
  #poitrine-group {
    display: none;
  }
  .configurateur {
    margin: auto;
  }
  .result {
    margin: auto;
    font-weight: 900;
    text-align: center;
    font-size: 30pt;
  }
  body {
    background-color: #fff;
  }
  .container {
    padding: 0px 10px 20px 10px;
  }
  .product-main .h2-bordered {
    padding: 10px;
    font-size: 14px;
    color: #595959;
    font-weight: bold;
    text-align: center;
    position: relative;
  }
  .product-main .h2-bordered:after {
    display: block;
    content: "";
    border-top: 1px solid #595959;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .product-main .h2-bordered strong {
    padding: 0 10px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    font-weight: 500;
    font-size: 14px;
  }
  .row.selector {
    display: flex;
  }
  .row.selector select {
    box-shadow: inset 0 -1.4em 1em 0 rgba(0, 0, 0, 0.02);
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 0.45em top 50%;
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns=http://www.w3.org/2000/svg width=24 height=24 viewBox=0 0 24 24 fill=none stroke=%23333 stroke-width=2 stroke-linecap=round stroke-linejoin=round className=feather feather-chevron-down%3E%3Cpolyline points=6 9 12 15 18 9%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    padding-right: 1.4em;
    background-size: auto 16px;
    border-radius: 0;
    display: block;
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 0 0.75em;
    height: 2.507em;
    font-size: 0.97em;
    max-width: 100%;
    width: 100%;
    color: #333;
    transition: color 0.3s, border 0.3s;
  }
  .warning-container {
    margin: 10px 0px;
    text-align: center;
  }
  .product-main span.warning {
    font-size: 12px;
    color: #ef2c2c;
  }
  .warning-container span.warning {
    font-size: 12px;
    color: #ef2c2c;
  }
  .warning-container span.warning a {
    color: #4573d2;
    text-decoration: underline;
  }
  .warning-container span.warning a:hover {
    color: #4573d2;
    text-decoration: underline;
  }
  .size-men {
    width: 50%;
    margin: auto;
  }
  .field-container {
    display: flex;
    margin: auto;
  }
  @media screen and (max-width: 992px) {
    .container {
      padding: 0px 0px 20px 0px !important;
    }
  }
  @media screen and (max-width: 850px) {
    #fitle-placeholder {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    #size-women {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .row.selector {
      flex-direction: column;
    }
    .medium-6 {
      margin: auto;
      width: 100%;
      max-width: 100%;
    }
    .medium-3 {
      margin: auto;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 20px;
    .field-container {
      flex-wrap: wrap;

      #Height {
        width: 100%;
        margin-bottom: 8px;
      }
      #Weight {
        width: 50%;
      }
      #Age {
        width: 45%;
      }
    }
    .size-men {
      width: 100%;
      margin: auto;
    }
  }
`

const HeadingMen = styled.h2`
  padding: 10px;
  font-size: 14px;
  color: #595959;
  font-weight: bold;
  text-align: center;
  position: relative;

  ::after {
    display: block;
    content: "";
    border-top: 1px solid #595959;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  strong {
    padding: 0 10px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    font-weight: 500;
    font-size: 14px;
  }
`

const SizeBoxWrapper = styled.div`
  display: flex;
`

const SizeSelectorBoxSection = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px;
  margin: 0px 3px 0px 0px;
  cursor: pointer;
  border: ${({ active }) =>
    active ? "2px solid #5db9bc" : "0px solid #5db9bc"};
  border-radius: ${({ active }) => (active ? "6px" : "0px")};

  select {
    background: ${({ active }) => (active ? "#D9F0F1" : "inherit")};
  }
`

const SizeSelectorBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "sizes" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        size_model_section {
          title
          list {
            text
            type
          }
        }
        size_gender_section {
          title
          list {
            text
            type
          }
        }
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        size {
          item
        }
      }
    }
    allDirectusLynePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_size {
          item
        }
      }
    }
  }
`

export default injectIntl(SizesPage)
